@use 'sass:math';
@import 'vm-variable.scss';
/* mixins starts */
/*width as percentage from 1 to 100 */
@mixin col-x-list($attr, $unit) {
  $max-number: 100;
  @for $i from 1 through $max-number {
    .#{$attr}-#{$i} {
      width: #{$i}#{$unit} !important;
    }
  }
}

@include col-x-list('width', '%');
/*if we need as any other attributes we have to include that only like height, padding, margin etc., */

/* Creating 8 grid system for roaster management starts */
@mixin row($size, $gutter-space) {
  $unit: 'px';
  .row-bng {
    margin-right: -#{$gutter-space}#{$unit} !important;
    margin-left: -#{$gutter-space}#{$unit} !important;
  }
}

@mixin grid-generator($size, $gutter-space) {
  $unit: 'px';
  @for $i from 1 through $grid-columns {
    .col-bng-#{$size}-#{$i} {
      width: percentage(math.div($i, $grid-columns));
      float: left;
      min-height: 1px;
      position: relative;
      padding-left: #{$gutter-space}#{$unit};
      padding-right: #{$gutter-space}#{$unit};
      margin: 0px;
    }
  }
}

@mixin grid-equal($size, $gutter-space) {
  $unit: 'px';
  @for $i from 1 through $grid-columns {
    /* @if $i%2 == 1{*/
    .col-bng-#{$size}-#{$i}-equal {
      width: percentage(math.div(1, $i));
      float: left;
      min-height: 1px;
      position: relative;
      padding-left: #{$gutter-space}#{$unit};
      padding-right: #{$gutter-space}#{$unit};
      margin: 0px;
      display: block !important;
    }
    /* }*/
  }
}

/* font sizing according to screen size */
@mixin font-sizing($attr, $unit, $size) {
  $max-number: 50;
  @for $i from 10 through $max-number {
    .#{$attr}-#{$size}-#{$i} {
      #{$attr} : #{$i}#{$unit} !important;
    }
  }
}

@mixin font-size($attr, $unit) {
  $max-number: 50;
  @for $i from 10 through $max-number {
    .#{$attr}-#{$i} {
      #{$attr} : #{$i}#{$unit} !important;
    }
  }
}

@mixin attr-properties($attr, $unit) {
  $max-number: 50;
  @for $i from 10 through $max-number {
    .#{$attr}-#{$i} {
      #{$attr}: #{$i}#{$unit} !important;
    }
  }
}

@mixin respond-from($media) {
  @if $media == xs {
    @media (min-width: $vm-xs-min-width) {
      @content;
    }
  } @else if $media == sm {
    @media (min-width: $vm-sm-min-width) {
      @content;
    }
  } @else if $media == md {
    @media (min-width: $vm-md-min-width) {
      @content;
    }
  } @else if $media == lg {
    @media (min-width: $vm-lg-min-width) {
      @content;
    }
  } @else if $media == xl {
    @media (min-width: $vm-xl-min-width) {
      @content;
    }
  }
}

@include respond-from(xs) {
  @include grid-generator(xs, 4);
  @include grid-equal(xs, 4);
  @include row(xs, 4);
  @include font-sizing('font-size', 'px', xs);

}

@include respond-from(sm) {
  @include grid-generator(sm, 4);
  @include grid-equal(sm, 4);
  @include row(sm, 4);
  @include font-sizing('font-size', 'px', sm);
}

@include respond-from(md) {
  @include grid-generator(md, 8);
  @include grid-equal(md, 8);
  @include row(md, 8);
  @include font-sizing('font-size', 'px', md);
}

@include respond-from(lg) {
  @include grid-generator(lg, 12);
  @include grid-equal(lg, 12);
  @include row(lg, 12);
  @include font-sizing('font-size', 'px', lg);
}

@include respond-from(xl) {
  @include grid-generator(xl, 12);
  @include grid-equal(xl, 12);
  @include row(xl, 12);
  @include font-sizing('font-size', 'px', xl);
}

@include font-size('font-size', 'px');
@include attr-properties('margin-top', 'px');
/* Creating 8 grid system for roaster management starts */

/* mixins ends */
