@use "sass:map";
@import "scss/vm-variable.scss";
@import "scss/vm-mixins.scss";
@import "/node_modules/angular-notifier/styles";
$theme-colors: (
  "primary":    #0D9CDE,
  /*"secondary":  #ff0000,
  "success":    #ff0000,
  "info":       #ff0000,
  "warning":    #ff0000,
  "danger":     #ff0000,
  "light":      #ff0000,*/
  "completed":  #1ABA00,
  "dark":       #263746
);

@import "/node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");


@import "/node_modules/bootstrap/scss/bootstrap.scss";

@import '@angular/material/theming';
@include mat-core();


/* For use in src/lib/core/theming/_palette.scss */
$my-blue: (
    50 : #007bff,
    100 : #007bff,
    200 : #007bff,
    300 : #007bff,
    400 : #007bff,
    500 : #007bff,
    600 : #007bff,
    700 : #007bff,
    800 : #007bff,
    900 : #007bff,
    A100 : #007bff,
    A200 : #007bff,
    A400 : #007bff,
    A700 : #007bff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


// below creates a primary palette with three shades of blue
$my-app-primary: mat-palette($my-blue, 100, 50, 200);
$my-app-accent:  mat-palette($mat-blue-grey);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent);
@include angular-material-theme($my-app-theme);

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';


$primary: #0D9CDE;
$dark: #263746;
$completed: #5BC05F;

body div:not(mat-icon) {
	font-family: Helvetica,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,Noto Sans,Liberation Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
}

.footer {
  background-color: black;
  padding: 10px 20px;
  color: white;
  display: flex;
}
.footer-menu {
  flex-grow: 1;
}
.footer > * {
  margin: 10px 0;
}
.footer-menu a, .footer-menu span {
  padding: 0 15px;
  cursor: pointer;
  color: white;
  display: inline-block;
}
.footer-menu a:hover{
  text-decoration: none;
}
.footer-menu > :not(:last-child){
  border-right: 2px solid #fff;
}

.boeing-logo {
  width: 150px;
  display: inline;
}

.boeing-logo, .copyright {
  margin: 10px;
}

.app-container {
  flex-grow: 1;
}


.menu-btn {
  cursor: pointer;
  padding-left: 15px;
  display: none;
}
.menu-btn span {
  display: block;
  width: 22px;
  height: 2px;
  background: #fff;
  margin: 4px 0;
  border-radius: 15px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.main-nav .material-icons{
  font-size: 30px;
}

.navbar-brand {
  font-weight: bold;
  margin-left: 20px;
}
.nav-link {
  cursor: pointer;
  user-select: none;
}


.full-height {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.profile-menu {
  .mat-menu-content:not(:empty) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .profile-info {
      text-align: center;
      padding: 20px 20px 10px 20px;
  }
  .full-name {
    font-weight: bold;
    font-size: 20px;
  }
  .email {
    color: #777;
    font-size: 14px;
  }

  .menu-btn-white {
    color: $primary;
    justify-content: center;
    display: flex;
  }

  .menu-btn-primary {
    background-color: $dark;
    justify-content: center;
    text-align: center;
    color: white;
  }

  .menu-btn-primary *{
    font-weight: bold;
    color:white;
  }

  .menu-btn-primary span{
    margin: 20px 0;
  }

  .menu-btn-primary:hover:not([disabled]), .menu-btn-primary.cdk-program-focused:not([disabled]),
  .menu-btn-primary.cdk-keyboard-focused:not([disabled]), .menu-btn-primary-highlighted:not([disabled]) {
    background: black;
  }

  .mat-mdc-menu-content{
      padding-bottom: 0;
  }
}
@media screen and (max-width: 1200px) {
  .footer {
  flex-direction: column;
  }
}
@media screen and (max-width: 992px) {
  .menu-btn{
    display: block;
  }

  body.menu-opened {
    overflow: hidden;

    .left-sidebar-col {
      left: 0px;
      -webkit-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      overflow-y: scroll;
      height: 100%;
      box-shadow: unset;
    }

    .menu-btn span:first-child {
      transform: rotate(-45deg) translateX(-2px);
      width: 13px;
    }
    .menu-btn span:last-child {
      transform: rotate(45deg) translateX(-2px);
      width: 13px;
    }

    .menu-backdrop {
      width: 100%;
      height: 100%;
      background: black;
      position: absolute;
      z-index: 998;
      opacity: 0.5;
      display: block;
    }
  }
}

.wrapper-header-search-and-title {
  display: flex;
  justify-content: space-between;
  min-height: 100px;
  margin-top: 20px;
}

.vm-upload-mat-dialog {
  width: 100vw;
  max-width: 100vw !important;
  height: 85%;
  position: absolute !important;
  top: 120px;
}

.mat-dialog-actions {
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 52px;
  align-items: center;
  box-sizing: content-box;
  margin-bottom: -24px;
}


@import "scss/vm-mixins.scss";
html, body {
  height: 100%;
  overflow-x: hidden;
  margin: 0px;
}

body {
  font-size: 16px;
}

input, select {
  font-size: 100%;
}

body,
div {
  font-family: $font-noto-normal !important;
}

body .btn,
body .btn-large,
body .btn-small {
  font-family: $font-noto-normal !important;
}

.cdk-global-scrollblock {
  position: initial;
  width: initial;
  overflow: hidden;
}

.invisible-tabs {
  > .mat-tab-header {
    display: none !important;
  }
}

.mat-tab-label-active {
  &.mat-tab-label .mat-tab-label-content {
    color: #263746;
  }
}

.mat-tab-label {
  opacity: 1 !important;

  &-active .mat-tab-label-content {
    color: #263746;
  }

  &-content {
    font-weight: bold !important;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 2px;
  }
}

.mat-expansion-panel-header-description {
  color: #4a4a4a !important;
  font-size: medium;
}

.mat-expansion-panel-header-title {
  color: #4a4a4a !important;
  font-size: 16px;
  font-weight: 600 !important;
  font-family: $font-noto-normal;
}

.row div {
  font-family: $font-noto-light !important;
}

.vjs-current-time,
.vjs-duration {
  width: 36px !important;
}

.mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus {
  background-color: transparent;
}

@media only screen and (min-width: $vm-xs-min-width) and (max-width: $vm-md-max-width) {
  /*mat-tab-group {
      margin-left: -7px;
      width: 104%;
  }*/
  .mat-tab-label {
    min-width: 50% !important;
  }
  .mat-tab-body-content {
    overflow: inherit !important;
  }
}

::ng-deep .snack-color {
  //background-color: #009BDE;
  background-color: $black;
  //background-color: $primary-blue;
  justify-content: center;
  font-size: 100% !important;
  color: #F0F0F0 !important;
}

*:focus {
  outline: none;
}

// .text-primary {
//   color: $primary-blue !important;
// }

.cursor-pointer {
  cursor: pointer !important;
}

::ng-deep .vm-dropdown-menu {
  cursor: pointer;
  /*display: inline-block;*/
  .dropdown-container {
    border-bottom: 2px solid darkgray;
    background: $input-bg;
    cursor: pointer;
    position: relative;
    padding: 0 10px;

    &.open {
      border-bottom: 2px solid $primary-blue;
    }
  }

  .dropdown-menu {
    width: 100%;
    border-radius: 0;
    min-width: initial !important;
    box-shadow: 0 .2rem 1rem rgba(0, 0, 0, .15) !important;
    border: 0px;

    .dropdown-item {
      padding: 3px 10px;

      &.active, &:active, &:hover, &:focus {
        background-color: $input-bg;
      }
    }

  }
}

::ng-deep .fixed-message {
  position: fixed;
  top: 55px;
  left: 0px;
  right: 0px;
  z-index: 2000;
}

.font-weight-600 {
  font-weight: 600 !important;
}

::ng-deep .vm-mat-dialog {
  position: relative !important;
  max-height: calc(100vh - 85px) !important;
  top: 30px;
  max-width: 783px !important;
  //max-height: 79vh !important;
  // .mat-dialog-container{
  // 	max-height: initial !important;
  // }
  .mat-dialog-content {
    max-height: 56vh !important;
    @media only screen and (min-width: $vm-xs-min-width) and (max-width: $vm-md-max-width) {

    }
    font-family: $font-noto-normal;
  }
}

::ng-deep {
  .cdk-overlay-container {
    z-index: 2100;
  }
}

.vm-btn {
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
  line-height: 36px;
}

.vm-icon-mouseover {
  &:hover {
    color: $primary-blue !important;
  }

  &:focus {
    color: $primary-blue !important;
  }

  &:active {
    color: $primary-blue !important;
  }
}

.height-18 {
  height: 18px !important;
}

@media only screen and (min-width: $vm-xs-min-width) and (max-width: $vm-md-max-width) {
  ::ng-deep .tooltip[role="tooltip"] {
    display: none !important;
  }
}

.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: '';
    margin-right: 0;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    background: white;
    border: 2px solid $check-box-color;
    border-radius: 2px;
  }

  &:checked + label:before {
    background: $check-box-color;
    border: 2px solid $check-box-color;
  }

  &:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 11px;
    left: 6px;
    width: 7px;
    height: 15px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.upload-box {
  border: 2px dashed $border-gray;
  border-radius: 4px;
  padding: 15px;
  margin-top: 15px;
}

.mat-card {
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: relative;
  padding: 6px !important;
  border-radius: 4px;
  overflow: hidden;
}

.mat-chip {
  min-height: 18px;
  height: 1px;
  display: flex !important;
}

#action-dropdown:focus {
  border-bottom: 2px solid $primary-blue !important;
}

.uploadProgress {
  position: relative;
  height: 8px;
  display: inline-block;
  width: 80%;
  background-color: lightgray;
  background-clip: padding-box;
  margin: 0.7rem 1rem 0.7rem 1rem;
  overflow: hidden;
  border-radius: 2px;

  .determinateBar {
    position: absolute;
    background-color: inherit;
    top: 0;
    bottom: 0;
    background-color: #588000;
    transition: width .3s linear;
  }

}

.upload-action-icon {
  margin-left: 10px;
}

input::placeholder {
  font-size: 15px;
  font-family: $font-noto-light !important;
}

.dropdown.multiselect input[type="checkbox"] {
  display: none;
}

.dropdown.multiselect input[type="checkbox"] + label:before {
  content: '\00a0';
  width: 15px;
  display: inline-block;
  padding-right: 20px;
}

.dropdown.multiselect input[type="checkbox"]:checked + label:before {
  content: '\2713';
  padding-right: 20px;
}

.mat-standard-chip {
  padding: 5px 5px !important;
  border-radius: 6px !important;
  min-height: 25px !important;
  background-color: #DFF0F7 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex !important;
}


.searchBar {
  position: absolute;
  top: 12px;
  right: 11px;
  color: darkgrey;
}


.video-grid-view {
  padding-top: 10px;
  background-color: #F7F7F7;
  .mat-chip-list-wrapper {
    margin: 0px -4px !important;
    height: 100% !important;
  }

  .grid-view-margin {
    margin-bottom: 8px;
    @media only screen and (max-width: $vm-sm-max-width) {
      margin-bottom: 8px !important;
    }
    @media only screen and (min-width: $vm-md-min-width) and (max-width: $vm-md-max-width) {
      margin-bottom: 16px !important;
    }
    @media only screen and (min-width: $vm-lg-min-width) {
      margin-bottom: 24px !important;
    }
  }

  .video-tags {
    white-space: normal;
    height: 30px;
    overflow: hidden;
    max-height: 60px;
    overflow: hidden;
    @media only screen and (min-width: $vm-md-min-width) and (max-width: $vm-md-max-width) {
      max-height: 60px;
    }
  }

  .video-container-body {
    padding: 10px;
  }

  .video-title {
    color: $secondary-blue;
    line-height: 24px;
    font-weight: 600;
    font-size: 25px;
    display: block;
    width: 100%;
    position: relative;
    z-index: 2;

    p {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-bottom: 5px;
      margin: 0 !important;
      font-weight: bold;
      letter-spacing: 0.8px;
      height: 30px;
      padding-right: 12px;
    }

    .icon-status-grid-view {
      position: absolute;
      top: 0;
      right: 30px;
    }
  }

  .bookmark-icon-container {
    position: absolute;
    left: 13px;
    top: 13px;
    background-color: rgba(0, 0, 0, 0.4);
    color: $white;
    border-radius: 4px;
    line-height: 1;
    user-select: none;
    display: block;
    width: 20px;
    height: 24px;
    z-index: 10;

    .bookmark-icon {
      position: absolute;
      left: -2px;
    }

    // for IE
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .bookmark-icon {
        position: absolute;
        left: -2px;
        top: 3px;
      }
    }
  }

  .video-view-count {
    text-align: left;
    position: absolute;
    bottom: 3px;
    left: 3px;
    font-size: 11px !important;

    &.grid-count {
      background: rgba(0, 0, 0, 0.6);
      color: $white;
      padding: 0px 3px;
      border-radius: 5px;
      line-height: 1.4;
    }
  }

  .watchDuration-grid {
    text-align: right;
    position: absolute;
    bottom: 3px;
    right: 3px;
    font-size: 11px !important;
    background-color: rgba(0, 0, 0, 0.6);
    color: $white;
    padding: 0px 3px;
    border-radius: 5px;
    line-height: 1.4;
    z-index: 3 !important;
  }

  .video-text {
    margin-top: 10px;
    margin-bottom: 10px;

    .desc-text {
      font-size: 16px;
      overflow: hidden;
      width: 100%;
      max-height: 42px;
      line-height: 21px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .video-container {
    min-height: 261px;
    margin: 0px;
    padding-bottom: 0px;
    height: 100% !important;
    overflow: visible !important;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #000;
      content: "";
      opacity: 0;
      transition: opacity 15ms linear, background-color 15ms linear;
      z-index: 1;
    }

    &:hover:before {
      opacity: 0.04;
      transition: opacity 15ms linear, background-color 15ms linear;
    }
  }
}

.dropdown {
  cursor: pointer;

  .filter-text {
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px;
    vertical-align: middle;
    margin-left: 3px;
  }

  .filter-icon, .sort-icon {
    color: rgba(0, 0, 0, 0.87);
    vertical-align: middle;
  }
}


.bg-white {
  background-color: $white;
}

::ng-deep .vm-select {
  .input-field {
    min-width: 100% !important;
    margin: 0 !important;
    background-color: #ebebeb !important;
  }

  .select-dropdown.form-control {
    padding-left: 10px !important;
    height: 48px !important;
  }

  .select-dropdown.ng-trigger-openClose {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .dropdown-content li > a,
  .dropdown-content li > span {
    color: rgba(0, 0, 0, .8) !important;
  }
}

::ng-deep .header-fixed {
  .action-dropdown {
    cursor: pointer;
    display: inline-block;

    .dropdown-container {
      border-bottom: none;
      background: $input-bg;
      cursor: pointer;
      position: relative;
      padding: 0 11px 0 14px;
      height: 44px;

      &.open {
        border-bottom: 2px solid $primary-blue;
      }
    }

    .dropdown-menu {
      width: 100%;
      border-radius: 0;
      min-width: 150px !important;
      box-shadow: 0 .2rem 1rem rgba(0, 0, 0, .15) !important;
      border: 0;
      left: auto !important;
      right: 0 !important;

      .dropdown-item {
        padding: 3px 10px;

        &.active, &:active, &:hover, &:focus {
          background-color: $input-bg;
        }
      }

    }

    .action-title {
      font-weight: 600 !important;
      color: rgba(0, 0, 0, .6) !important;
      font-size: 14px;
      font-family: $font-noto-medium !important;
    }
  }
}

@media only screen and (max-width: $vm-md-max-width) {
  ::ng-deep .vm-tabs {
    .mat-tab-label {
      min-width: auto !important;
    }
  }
  .bookmark-icon-container {
    padding: 17px 15px;

    .bookmark-icon {
      position: absolute;
      left: 3px !important;
      top: 4px;
    }
  }
}

.bookmark-success {
  color: $primary-blue !important;
}

.vm-upload-button {
  background: $secondary-blue !important;
  padding: 0px 10px;
  line-height: 32px;

  &.disable-button {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12) !important;
    border: none;
  }
}

.setThumbnailImage {
  border: 1px dashed #0077B7;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  z-index: 2;

  &.grid-thumbnail {
    height: 56%;
    border-radius: 2px;
    border: 0px !important;
    border-bottom: 1px solid #d6d6d6 !important;

    img {
      width: 100% !important;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      z-index: 2;
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}

.thumbnail-container {
  width: 142px;
  height: 80px;
  float: left;
  @media only screen and (max-width: $vm-sm-max-width) {
    width: 124px;
  }
  @media only screen and (max-width: $vm-md-max-width) {
    width: 119px;
    height: 67px;
  }
}

.video-content-container {
  width: calc(100% - 142px);
  float: left;
  height: auto;
  padding-left: 30px;
  padding-right: 12px;
  @media only screen and (max-width: $vm-sm-max-width) {
    padding-left: 8px;
    padding-right: 4px;
    width: calc(100% - 142px);
  }
  @media only screen and (min-width: $vm-md-min-width) and (max-width: $vm-md-max-width) {
    padding-left: 16px;
    padding-right: 8px;
    width: calc(100% - 142px);
  }
}

::ng-deep .mat-progress-spinner circle,
::ng-deep .mat-spinner circle {
  stroke: black !important;
}

.dropdown.show {
  .filter-options {
    color: $primary-blue;
  }
}

.sort_icon:hover {
  color: $primary-blue;
}

::ng-deep .dropdown {
  &-close {
    .material-label {
      display: none;
    }

    .vm-multiselect-dropdown {
      .c-btn {
        span:first-child {
          margin-top: 0;
          color: rgba(0, 0, 0, 0.6) !important;
        }
      }
    }
  }

  &-open {
    .material-label {
      display: block;
    }

    .vm-multiselect-dropdown {
      .c-btn {
        span:first-child {
          margin-top: 10px;
          display: none;
        }
      }
    }
  }
}

::ng-deep .video-grid-view {
  .vm-dropdown-menu {
    min-width: 128px !important;
  }
}

::ng-deep .vm-tooltip {
  .tooltip-inner {
    background-color: #666666 !important;
    font-size: 11px !important;
    letter-spacing: 0.4px;
  }
  &.bs-tooltip-top {
    .tooltip-arrow.arrow::before {
      border-top-color: #666666 !important;
    }
  }
  &.bs-tooltip-right {
    .tooltip-arrow.arrow::before {
      border-right-color: #666666 !important;
    }
  }
  &.bs-tooltip-bottom {
    .tooltip-arrow.arrow::before {
      border-bottom-color: #666666 !important;
    }
  }
  &.bs-tooltip-left {
    .tooltip-arrow.arrow::before {
      border-left-color: #666666 !important;
    }
  }
}

.bookmark-active, .deleted-bookmark-item {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
  display: none !important;
}

::ng-deep .mat-tab-header {
  border-bottom: none;
}


.action-dropdown-menu {
  padding: 0;
  margin: 0;
  min-width: 200px;
  &.menu-last-item {
    transform: translateY(-125%) !important;
  }
  .action-dropdown-item {
    padding: 10px 15px;
  }
}

.video-text {
  display: flex !important;
  align-items: center;

  .share-mode-icon {
    margin-right: 5px;
    display: flex;
    z-index: 2;
    .material-icons {
      font-size: 20px !important;
      cursor: pointer;
    }
  }
  .description {
    width: 100%;
    display: block !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.video-list-item-icons {
  display: flex;
  justify-content: flex-end;
}

.load-more {
  margin-bottom: 10px;

  ngx-loading ::ng-deep .spinner-circle {
    font-size: 4px !important;
  }
  .custom-class {
    color: rgba(0,0,0,0.6);
    span {
      font-size: 12px;
      margin-top: 75px;
    }
  }
}

.loading-show {
  height: 65px;
}

.video-list-view-container {
  padding: 0 2px;

  .video-list-view {
    padding: 16px;
    margin-bottom: 0px !important;
    border-bottom: 1px solid #E5E5E5;
    margin-left: 0 !important;
    margin-right: 0 !important;

    &:hover {
      background-color: $div-highlight !important;
      border-radius: 4px !important;
    }

    @media only screen and (max-width: $vm-md-max-width) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .video-title {
      color: $primary-blue;
      margin: 0;
      font-weight: 600;
      font-size: 17px !important;
      display: table;
      table-layout: fixed;
      width: 130% !important;

      p {
        display: table-cell;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-bottom: 3px;
        margin: 0px !important;
        letter-spacing: 0.8px;
        line-height: 1;
      }
    }

    .video-text {
      font-size: 12px !important;
      overflow: hidden; 
      width: 130% !important;
      text-overflow: ellipsis;
      margin-bottom: 6px;
      white-space: nowrap;
    }

    // .video-tags {
    //   // height: 30px;
    //   // overflow: hidden;
    // }

    .watchDurationList {
      text-align: right;
      position: absolute;
      bottom: 3px;
      background-color: rgba(0, 0, 0, 0.6);
      color: $white;
      padding: 0px 3px;
      line-height: 1.5;
      border-radius: 7px;
      right: 3px;
      @media only screen and (max-width: $vm-xs-max-width) {
        font-size: 9px !important;
      }
      @media only screen and (min-width: $vm-sm-min-width) {
        font-size: 11px !important;
      }
    }

    .video-view-count {
      font-weight: 500;
      font-size: 15px !important;
      text-align: center;
    }

    .vert-icon {
      color: rgba(0, 0, 0, 0.87);;
      cursor: pointer;
    }

  }
}
.wrap-list-file {
  width: 100%;
  overflow-x: hidden;
  .file-cell {
    &.name, &.modified-by {
      //min-width: 300px !important;
      width: 30%;
    }

    &.modified-date, &.kind-size {
      //min-width: 200px !important;
      width: 20%;
    }
  }
}

.vm-upload-mat-dialog {
  width: 100vw;
  max-width: 100vw !important;
  height: 85%;
  position: absolute !important;
  top: 120px;
}

bs-tooltip-container.tooltip {
  top: 0;
  left: 0;
  visibility: hidden;
  display: none;

  &.show {
    visibility: visible;
    display: block;
  }
}

::ng-deep .mat-form-field-infix input {
  height: 30px;
}

.required-label {
  font-size: 14px;
  margin-top: 10px;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.ellipsis-wrap {
  display: inline-block; 
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 50px);
}

.file-name-wrap {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
}

.file-name-wrap-short {
  max-width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
}

.message-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}
::ng-deep .sr-ui-messages ul > li {
  display: flex;
}

::ng-deep .sr-ui-messages  {
  max-width: 100%;
}

::ng-deep .sr-messages  {
  max-width: 95%;
}

@media only screen and (max-width: 540px) {
  .file-name-wrap {   max-width: 40%; }
}

@media only screen and (max-width: 370px) {
  .file-name-wrap {   max-width: 15%; }
}

::ng-deep .sr-ui-messages .sr-ui-messages-detail {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}

::ng-deep .sr-ui-messages .sr-message-content{
  display: flex !important;
  align-items: center;
  max-width: 100%;
}

::ng-deep .sr-ui-messages ul {
  max-width: 100%;
}
   
::ng-deep .sr-ui-messages .sr-message-content .sr-ui-messages-close-icon {
  margin-left: auto;
}

::ng-deep .errorBorder { 
  .mat-form-field-outline {
      color: #f44336 !important;
  }
  .mat-form-field-outline-start, .mat-form-field-outline-end, .mat-form-field-outline-gap {
    border-width: 2px !important;
  }
  .mat-form-field-label {
    mat-label {
      color: red !important;
      font-weight: bold;
      background-color: white;
    }
  }
}

.calendar-icon-btn {
  margin-left: 10px !important;
}

::ng-deep .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0077B7 !important;
}
::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
  color: #0077B7 !important;
}

.ng-select.vm-ng-select ::ng-deep .ng-select-container {
  &.ng-appearance-outline {
     &:focus-within {
        &:after {
          border: solid 1px #0077B7 !important;
        }
        .ng-placeholder {
          color: #0077B7 !important;
        }
     }
  }
}

.ng-select.error-vm-ng-select ::ng-deep .ng-select-container {
  &.ng-appearance-outline {
      &:after {
        border: solid 2px #f44336 !important;
      }

      &:hover {
        &:after {
          border: solid 2px #f44336 !important;
        }
      }

      .ng-placeholder {
        font-size: 14px;
        font-weight: bold;
        color: #f44336 !important;
      }
  }
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick  {
  color: #f44336 !important;
}

::ng-deep .error-mat-form-field-select {
  .mat-form-field-outline {
    color: #f44336 !important;
  }
  .mat-form-field-outline-start, .mat-form-field-outline-end, .mat-form-field-outline-gap {
    border-width: 2px !important;
  }
  .mat-form-field-label {
    color: #f44336 !important;
  }
}
div.cdk-overlay-container,div.cdk-overlay-container .cdk-global-overlay-wrapper,div.cdk-overlay-container .cdk-overlay-backdrop,div.cdk-overlay-container .cdk-overlay-connected-position-bounding-box,div.cdk-overlay-container .cdk-overlay-pane {
  z-index: 3000 !important;
}

.search-section {
  height: 44px;
  position: relative;
  border: 1px solid #c1c1c1;
  border-radius: 4px;

  .search-error {
    padding-left: 10px;
    font-size: 11px;
    color: red;
  }

  .input-search {
    background: $white;
    border: none;
    width: 100%;
    height: 100%;
    border-bottom: 0px;
    margin-bottom: 0px;
    padding-left: 11px;
    border: 0;
    box-shadow: none !important; // new UI
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-right: 40px;

    &:focus::placeholder {
      color: transparent;
    }

    &:focus {
      border: solid 1px #0077B7 !important;
    }

    &::-ms-clear {
      display: none;
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px !important;
      font-weight: 600 !important;
      font-family: $font-noto-medium !important;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px !important;
      font-family: $font-noto-medium !important;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px !important;
      font-weight: 600 !important;
      font-family: $font-noto-medium !important;
    }
  }

  .searchBar {
    .material-icons {
      color: darkslategrey;
    }
  }
}

.mobile-search {
  background-color: $bg-blue;

  .material-icons {
    color: $primary-blue;
  }
}

.add-collection-panel-mat-dialog {
  width: 100vw;
  max-width: 100vw !important;
  height: calc(100% - 120px);
  position: absolute !important;
  top: 120px;
}
.date .mat-form-field-suffix{
  right: -0.5em !important;
}
.datePicker .mat-form-field-suffix{
  right: -0.7em !important;
}

.mat-sort-header-arrow, [dir=rtl] .mat-sort-header-position-before .mat-sort-header-arrow {
  margin: 0 0 0 6px;
  color: #0077B7;
}

.float-right{
  float: right;
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button, .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  width: 2.5em;
  height: 2.5em;
}