@import "font.scss";

/*global variables starts */
$grid-columns: 8;
$vm-xs-min-width: 0px;
$vm-xs-max-width: 575px;
$vm-sm-min-width: 576px;
$vm-sm-max-width: 767px;
$vm-md-min-width: 768px;
$vm-md-max-width: 991px;
$vm-lg-min-width: 992px;
$vm-lg-max-width: 1199px;
$vm-xl-min-width: 1200px;
$vm-xl-max-width: 1400px;

$font-noto-bold: "NotoSansBold", "Noto Sans Bold", sans-serif;
$font-noto-normal: "NotoSansRegular", "Noto Sans Regular", sans-serif;
$font-noto-bold: "NotoSansBold", "Noto Sans Bold", sans-serif;
$font-noto-italic: "NotoSansItalic", "Noto Sans Italic", sans-serif;
$font-noto-medium: "NotoSansMedium", "Noto Sans Medium", sans-serif;
$font-noto-light: "NotoSansLight", "Noto Sans Light", sans-serif;

$primary-blue: #0077B7;
$bg-blue: #E8F0FC;
$white: #ffffff;
$black: #000000;
$light-gray: #effafa;
$border-gray: #e2e2e2;
$div-highlight: #F5F5F5;
$input-bg: #ebebeb;
$secondary-blue: #0077B7;
$bookmark-success: #0077B7;

$check-box-color: #000000;
$check-box-padding-left: 20px;
$check-box-padding-left-dialog: 10px;

/* global variable ends */
